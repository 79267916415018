.mainnavbar {
    color: white;
}

.indiLink {
    justify-content: center;
    align-items: center;
    display: flex;
}

.indiLink>svg {
    padding-left: 0.2em;
}